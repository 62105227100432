import PropTypes from 'prop-types'

import { FILTER_SIEVE_COLLAPSED } from 'modules/discover/discoverConstants'
import useTracker from 'hooks/useTracker'
import FilterOption from 'modules/discover/DiscoverSieve/FilterOptions/FilterOption/FilterOptionContainer'
import styles from './FilterOptions.module.scss'

const FilterOptions = ({
  modalFiltersCoutn,
  allFiltersCount,
  scrollToTop,
  filterBy,
  count,
  industries,
  handleOpenModal,
  handleSetFilterOption,
  handleResetSieve
}) => {
  const { handleUseTracker } = useTracker()

  const handleGetSelected = filterName => {
    const selected = filterBy.find(({ name }) => name === filterName)
    return selected ? selected.item : ''
  }

  const handleSelectOption = filter => {
    handleSetFilterOption(filter)
    // Track filter changes
    handleUseTracker('FilterUsed', filter.name, filter.item)
    // Scroll to top on select if scrollToTop === true
    if (scrollToTop) window.scrollTo(0, 200)
  }

  return (
    <>
      {(industries.length
        ? [
            {
              label: 'Type',
              name: 'industry',
              multiple: true,
              items: industries
            },
            ...FILTER_SIEVE_COLLAPSED
          ]
        : FILTER_SIEVE_COLLAPSED
      ).map((filter, index) => (
        <FilterOption
          key={index}
          {...filter}
          count={count}
          selectedOption={handleGetSelected(filter.name)}
          handleOptionClick={handleSelectOption}
        />
      ))}
      <div className={styles.Btns}>
        <button
          type="button"
          className={`${styles.FilterBtn} ${modalFiltersCoutn ? styles.FilterBtnActive : ''}`}
          onClick={handleOpenModal}
        >
          More {!!modalFiltersCoutn && ` (${modalFiltersCoutn})`}
        </button>

        {!!allFiltersCount && (
          <button type="button" className={styles.ClearBtn} onClick={handleResetSieve}>
            Clear
          </button>
        )}
      </div>
    </>
  )
}

FilterOptions.propTypes = {
  modalFiltersCoutn: PropTypes.number,
  allFiltersCount: PropTypes.number,
  count: PropTypes.number,
  scrollToTop: PropTypes.bool,
  filterBy: PropTypes.arrayOf(PropTypes.object),
  industries: PropTypes.arrayOf(PropTypes.string),
  handleOpenModal: PropTypes.func,
  handleSetFilterOption: PropTypes.func,
  handleResetSieve: PropTypes.func
}

FilterOptions.defaultProps = {
  modalFiltersCoutn: 0,
  allFiltersCount: 0,
  count: 0,
  scrollToTop: false,
  filterBy: [],
  industries: [],
  handleOpenModal: () => {},
  handleSetFilterOption: () => {},
  handleResetSieve: () => {}
}

export default FilterOptions
