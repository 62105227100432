import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { resetOneFilterAction } from 'redux/discover/actions'
import FilterOption from './FilterOption'

const FilterOptionContainer = props => {
  const {
    userData: { userId }
  } = useSelector(({ auth }) => auth)
  const urls = useSelector(({ url }) => url)

  const dispatch = useDispatch()

  const resetOneFilter = useCallback(payload => dispatch(resetOneFilterAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    ...urls,
    userId,
    handleResetOneFilter: resetOneFilter
  }

  return <FilterOption {...updatedProps} />
}

export default FilterOptionContainer
