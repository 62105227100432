import PropTypes from 'prop-types'

import { Card } from '@moneymade/moneymade-ui'
import LockBlock from 'components/ui/LockBlock/LockBlockContainer'
import styles from './LockComponent.module.scss'

const LockComponent = ({ title, isUserLogged, lock, children }) => (
  <section className={styles.LockComponent}>
    {lock && !isUserLogged ? (
      <Card>
        {lock && !isUserLogged && <LockBlock title={title} />}
        <div className={`${lock && !isUserLogged ? styles.Lock : ''}`}>{children}</div>
      </Card>
    ) : (
      children
    )}
  </section>
)

LockComponent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  lock: PropTypes.bool,
  isUserLogged: PropTypes.bool
}

LockComponent.defaultProps = {
  children: null,
  title: '',
  lock: false,
  isUserLogged: false
}

export default LockComponent
