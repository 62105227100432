import { Fragment } from 'react'
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const CustomDot = dynamic(() => import('./CustomDot/CustomDot'))

const Graph = ({ value, graph }) => {
  // Select color bassed on the difference
  const color = value < 0 ? '#ed2348' : '#14aea5'
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={graph} syncId="anyId">
        <defs>
          <linearGradient id="graphColorGreen" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#14aea5" stopOpacity={0.15} />
            <stop offset="100%" stopColor="#14aea5" stopOpacity={0} />
          </linearGradient>

          <linearGradient id="graphColorRed" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#ed2348" stopOpacity={0.15} />
            <stop offset="100%" stopColor="#ed2348" stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis dataKey="x" axisLine={false} tickLine={false} stroke={color} hide />
        <YAxis dataKey="y" axisLine={false} tickLine={false} hide />

        <Area
          type="monotone"
          dataKey="y"
          stroke={color}
          strokeWidth={1}
          fill={`url(#${value < 0 ? 'graphColorRed' : 'graphColorGreen'})`}
          animationDuration={3000}
          // eslint-disable-next-line react/no-unstable-nested-components
          dot={({ cx, cy, index }) => {
            if (index === graph.length - 1) {
              return <CustomDot key={index} cx={cx - 1.4} cy={cy + 1.4} value={value} />
            }

            return <Fragment key={index} />
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

Graph.propTypes = {
  graph: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Graph.defaultProps = {
  graph: 0,
  value: 0
}

export default Graph
