import { useState } from 'react'
import PropTypes from 'prop-types'
import { MainButton, Menu, MenuItem, Checkbox, Radio, Icon } from '@moneymade/moneymade-ui'

import styles from './FilterOption.module.scss'

const FilterOption = ({
  label,
  name,
  multiple,
  count,
  items,
  selectedOption,
  handleOptionClick,
  handleResetOneFilter
}) => {
  const [isMenu, setMenu] = useState(null)

  const handleClearFilter = filterName => {
    handleResetOneFilter(filterName)
    setMenu(null)
  }

  return (
    <>
      {!multiple && selectedOption.length ? (
        <button
          type="button"
          className={`${styles.FilterBtn} ${styles.FilterBtnActive}`}
          onClick={() => handleOptionClick({ name, item: selectedOption })}
        >
          {selectedOption}
          <Icon icon="icon-x-mark" className={styles.Close} size={11} />
        </button>
      ) : (
        <button
          type="button"
          className={`${styles.FilterBtn} ${selectedOption.length ? styles.FilterBtnActive : ''}`}
          onClick={event => setMenu(event.currentTarget)}
        >
          {label}
          {!!selectedOption.length && ` (${selectedOption.length})`}
          <Icon icon={`${isMenu ? 'icon-dropdown-up' : 'icon-dropdown-down'}`} className={styles.DropDown} size={11} />
        </button>
      )}

      <Menu position="bottom-left" isOpen={isMenu} className={styles.FilterList} handleClose={() => setMenu(null)}>
        {items.map((item, index) => (
          <MenuItem key={index} className={styles.FilterListItem}>
            {multiple ? (
              <Checkbox
                label={item}
                checked={selectedOption.includes(item)}
                onChange={() => handleOptionClick({ name, item, multiple })}
              />
            ) : (
              <Radio label={item} value={item} checked={selectedOption === item} onChange={() => setMenu(null)} />
            )}
          </MenuItem>
        ))}

        <MenuItem className={styles.Footer}>
          <button type="button" className={styles.ClearBtn} onClick={() => handleClearFilter(name)}>
            Clear
          </button>
          <MainButton size="sm" color="dark" weight="normal" fontType="main" onClick={() => setMenu(null)}>
            Show {count} Results
          </MainButton>
        </MenuItem>
      </Menu>
    </>
  )
}

FilterOption.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string),
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  count: PropTypes.number,
  handleOptionClick: PropTypes.func,
  handleResetOneFilter: PropTypes.func
}

FilterOption.defaultProps = {
  label: '',
  name: '',
  multiple: false,
  items: [],
  selectedOption: '',
  count: 0,
  handleOptionClick: () => {},
  handleResetOneFilter: () => {}
}

export default FilterOption
