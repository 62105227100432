import { Skeleton } from '@moneymade/moneymade-ui'

import styles from './SkeletonFilters.module.scss'

const SkeletonFilters = () => (
  <div className={styles.SkeletonFilters}>
    <Skeleton width="100%" height={38} className={styles.OnlyDesktop} />
    <Skeleton width="100%" height={38} className={styles.OnlyDesktop} />
    <Skeleton width="100%" height={38} className={styles.OnlyDesktop} />
    <Skeleton width={68} height={38} className={styles.OnlyDesktop} />
    <Skeleton width={118} height={38} className={styles.OnlyMobile} />
    <Skeleton width="100%" height={38} className={styles.OnlyMobile} />
  </div>
)

export default SkeletonFilters
