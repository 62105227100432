import { Icon, P3 } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'

import styles from './TypeFilter.module.scss'

const TypeFilter = ({ name, handleIsChecked }) => (
  <div className={styles.TypeFilter} onClick={handleIsChecked} role="presentation">
    <P3>{name}</P3>
    <Icon icon="icon-x-mark" size={7} />
  </div>
)

TypeFilter.propTypes = {
  name: PropTypes.string,
  handleIsChecked: PropTypes.func
}

TypeFilter.defaultProps = {
  handleIsChecked: () => {},
  name: ''
}

export default TypeFilter
