import { P1, P2, H5, P3, MainButton } from '@moneymade/moneymade-ui'
import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import PropTypes from 'prop-types'

import { toConvertMoney } from 'utils/convertors'
import { getCdnImg } from 'utils/getters'
import styles from './Offers.module.scss'

const Offers = ({
  name,
  description,
  logoUrl,
  investors,
  dataPointTitle1,
  dataPointTitle2,
  dataPointValue1,
  dataPointValue2,
  slug
}) => {
  const handlePlatformClick = query => {
    window.open(
      `https://w.mmin.io/platform-click/${query}?utm_source=moneymade&utm_campaign=discover&utm_medium=offer_card`,
      '_blank'
    )
  }

  return (
    <div
      aria-hidden
      // href={`https://w.mmin.io/platform-click/${slug}?utm_source=moneymade&utm_campaign=discover&utm_medium=offer_card`}
      // target="_blank"
      // rel="noreferrer"
      onClick={() => handlePlatformClick(slug)}
      className={styles.Offers}
    >
      <div className={styles.LeftSide}>
        <div className={styles.Header}>
          <ImgLazy src={getCdnImg(logoUrl, 48)} alt={name} width={48} height={48} />
          <div className={styles.Title}>
            <H5 weight="semibold">{name}</H5>
            <div className={styles.NewOffer}>
              <P3 weight="normal">New Offer</P3>
            </div>
          </div>
        </div>
        <div className={styles.Description}>
          <P2>{description}</P2>
        </div>
      </div>

      <div className={styles.RightSide}>
        <div className={styles.Header}>
          <div className={styles.DataPoint}>
            <P3 weight="light" className={styles.Title}>
              {dataPointTitle1}
            </P3>
            <P1 weight="normal">{dataPointValue1}</P1>
          </div>

          <div className={styles.DataPoint}>
            <P3 weight="light" className={styles.Title}>
              {dataPointTitle2}
            </P3>
            <P1 weight="normal">{dataPointValue2}</P1>
          </div>
        </div>
        <div className={styles.Btn}>
          <MainButton color="dark" size="sm" weight="normal" fontType="main">
            {`Join ${investors && investors > 0 ? toConvertMoney(investors) : ''} Investors`}
          </MainButton>
        </div>
      </div>
    </div>
  )
}

Offers.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  investors: PropTypes.string,
  dataPointTitle1: PropTypes.string,
  dataPointTitle2: PropTypes.string,
  dataPointValue1: PropTypes.string,
  dataPointValue2: PropTypes.string,
  slug: PropTypes.string,
  logoUrl: PropTypes.string
}

Offers.defaultProps = {
  name: '',
  description: '',
  investors: '',
  dataPointTitle1: '',
  dataPointTitle2: '',
  dataPointValue1: '',
  dataPointValue2: '',
  slug: '',
  logoUrl: ''
}

export default Offers
