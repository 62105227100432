import { Container, useWindowSize, H1, H5, P1 } from '@moneymade/moneymade-ui'

import styles from './DiscoverHeader.module.scss'

const DiscoverHeader = () => {
  const { screenWidth } = useWindowSize()

  return (
    <Container className={styles.DiscoverHeader} fullWidth>
      {/* <Link href={`/${ROUTES.trending}`}> */}
      <div className={styles.Link}>
        <H1 weight="semibold" className={styles.Title}>
          <span>Discover</span> your next investment
        </H1>

        {screenWidth >= 601 ? (
          <H5 weight="light" className={styles.Description}>
            Crypto, Farmland, Fine Wine...even Whiskey.
          </H5>
        ) : (
          <P1 weight="light" className={styles.Description}>
            Crypto, Farmland, Fine wine, Real Estate...
          </P1>
        )}
      </div>
      {/* </Link> */}
    </Container>
  )
}

export default DiscoverHeader
