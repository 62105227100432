import PropTypes from 'prop-types'
import { Input, Icon } from '@moneymade/moneymade-ui'

import useTracker from 'hooks/useTracker'
import styles from './Search.module.scss'

const Search = ({ scrollOnTyping, searchBy, handleSetStoreSearch }) => {
  const { handleUseTracker } = useTracker()

  const handleSearch = ({ target: { value } }) => {
    // Add text to the redux store
    handleSetStoreSearch(value)
    // Track seearch value. 3+ characters
    if (value.length > 3) handleUseTracker('Search', 'term', value)
    // Scroll to top when sive header is enabled
    if (scrollOnTyping) window.scrollTo(0, 200)
  }

  return (
    <div className={styles.Search}>
      <Input
        value={searchBy}
        placeholder="Search"
        inputSize="sm"
        shape="rounded"
        onChange={handleSearch}
        iconBefore={<Icon icon="icon-search" size={16} />}
        iconAfter={
          searchBy?.length ? (
            <button type="button" className={styles.CloseBtn} onClick={() => handleSetStoreSearch('')}>
              <Icon icon="icon-x-mark" size={16} />
            </button>
          ) : null
        }
      />
    </div>
  )
}

Search.propTypes = {
  scrollOnTyping: PropTypes.bool,
  searchBy: PropTypes.string,
  handleSetStoreSearch: PropTypes.func
}

Search.defaultProps = {
  scrollOnTyping: false,
  searchBy: '',
  handleSetStoreSearch: () => {}
}

export default Search
