import dynamic from 'next/dynamic'
import { Fragment, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, P2, useWindowSize } from '@moneymade/moneymade-ui'

import { findBanner } from 'utils/checkers'
import { getPrevPos } from 'utils'
import DiscoverSieve from 'modules/discover/DiscoverSieve/DiscoverSieveContainer'
import SubForm from 'modules/discover/PlatformPage/SubForm/SubForm'
import InvestmentCard from 'components/cards/InvestmentCard/InvestmentCard'
import HeaderBanner from 'components/banners/HeaderBanner/HeaderBanner'
import MobBanner from 'components/banners/MobBanner/MobBanner'
import useTracker from 'hooks/useTracker'

import {
  getFilteredInvestments,
  toSearchInvestments,
  getFilterContainsInvestments,
  toSortInvestments
} from 'modules/discover/discoverUtils'

import LockComponent from 'components/ui/LockComponent/LockComponent'
import DiscoverHeader from 'modules/discover/PlatformsTab/DiscoverHeader/DiscoverHeader'
import SkeletonFilters from 'components/skeletons/SkeletonFilters/SkeletonFilters'
import TypeFilter from 'modules/discover/DiscoverSieve/TypeFilter/TypeFilter'
import Offers from 'components/cards/Offers/Offers'
import ReadBlock from 'components/cards/ReadBlock/ReadBlock'
import styles from './PlatformsTab.module.scss'

const SkeletonCard = dynamic(() => import('components/skeletons/SkeletonCard/SkeletonCard'))
const NotFound = dynamic(() => import('components/ui/NotFound/NotFound'))

const toShuffle = array => {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]

    array[currentIndex] = array[randomIndex]

    array[randomIndex] = temporaryValue
  }

  return array
}

const PlatformsTab = ({
  industriesAll,
  investments,
  searchBy,
  sortBy,
  filterBy,
  filterContains,
  isAuthChecked,
  handleSetFilterOption,
  isLogged,
  banners,
  offers,
  articlesLimited,
  industriesAvgAll
}) => {
  const { screenWidth } = useWindowSize()
  const [banner, setBanner] = useState({ header: {}, inText: {}, rightRail: {}, platformBanner: {} })
  const [investmentsActual, setInvestmentsActual] = useState([...offers, ...investments])
  const { handleUseTracker } = useTracker()
  const randomOffers = toShuffle(offers)
  const combineOffers = useMemo(
    () =>
      articlesLimited.reduce(
        (acc, item) => {
          if (randomOffers[acc.activeIndex]) {
            return {
              activeIndex: acc.activeIndex + 1,
              items: [...acc.items, randomOffers[acc.activeIndex], item]
            }
          }

          return {
            activeIndex: 1,
            items: [...acc.items, randomOffers[0], item]
          }
        },
        { activeIndex: 0, items: [] }
      ),
    [articlesLimited, randomOffers]
  )
  // Sorted, filtered, searched investments
  const [filanPlatforms, platformsAmount] = useMemo(() => {
    // With applied filter
    const filtered = getFilteredInvestments(investmentsActual, filterBy)
    // With applied contains filter
    const filteredContains = getFilterContainsInvestments(filtered, filterContains)
    // Search by search string
    const searchedPremium = toSearchInvestments([...filteredContains], searchBy)
    // Sort investments by selected sort option
    const sortedInvestments = toSortInvestments([...searchedPremium], sortBy)
    // Show more button fucntionality
    let offerUpdte = [0]
    const onlyVisible = sortedInvestments.reduce((acc, investment, index) => {
      if (index > 8 && index % 4 === 0) {
        if (combineOffers.items.length === offerUpdte.length) offerUpdte = [0]
        else {
          offerUpdte.push(Number(offerUpdte.slice(-1)) + 1)
        }
      }
      return [
        ...acc,
        {
          ...investment,
          offer: offerUpdte.slice(-1)
        }
      ]
    }, [])
    return [onlyVisible, sortedInvestments]
  }, [combineOffers.items.length, filterBy, filterContains, investmentsActual, searchBy, sortBy])
  // Scroll to prev position if we have one
  useEffect(() => {
    if (window.prevRoute && window.prevRoute.includes('/discover/')) window.scrollTo(0, getPrevPos('discover'))
  }, [])

  useEffect(() => {
    // Find banner for this Page
    const foundBanner = findBanner(banners, 'discover')
    if (foundBanner) {
      setBanner(foundBanner)
      if (Object.keys(foundBanner?.header).length) handleUseTracker('BannerInView', 'header', '/discover')
      if (Object.keys(foundBanner?.rightRail).length) handleUseTracker('BannerInView', 'rightRail', '/discover')
    }
  }, [banners, handleUseTracker])

  useEffect(() => {
    // get investments with provided userId
    const handleInvestmentsCall = async () => {
      // if investments exist display it from Redux
      if (investments.length) {
        // sort investments by premium & paid and randomize it
        if (!window.topInvestment) {
          window.topInvestment = [...(offers || []), ...investments]
          setInvestmentsActual(window.topInvestment)
        } else {
          setInvestmentsActual(window.topInvestment)
        }
      }
    }

    handleInvestmentsCall()
  }, [investments, offers, investmentsActual])

  const checketType = useMemo(() => filterBy.find(({ name }) => name === 'industry'), [filterBy])

  const handleSelectOption = filter => {
    handleSetFilterOption(filter)
    // Track filter changes
    handleUseTracker('FilterUsed', filter.name, filter.item)
  }

  const industriesPlatform = useMemo(
    () =>
      investments
        .map(({ industries }) => industries.map(({ name }) => name))
        .reduce((acc, array) => {
          const notIncluded = array.filter(element => !acc.includes(element))
          return [...acc, ...notIncluded]
        }, []),
    [investments]
  )

  const checkIndexOffer = index => {
    if (index === 8 || (index > 8 && index % 4 === 0)) {
      return true
    }
    return false
  }

  const checkIndexDiscoverCard = index => {
    if (index === 4 || (index > 4 && index % 15 === 4)) return true

    return false
  }

  return (
    <div className={styles.PlatformsBg}>
      <DiscoverHeader />

      <LockComponent lock={false}>
        {Object.keys(banner?.header).length ? (
          screenWidth > 695 ? (
            <HeaderBanner {...banner.header} />
          ) : (
            <MobBanner {...banner.header} header />
          )
        ) : null}

        <Container className={styles.PlatformsTab}>
          <div className={styles.LeftSide}>
            {investmentsActual.length ? (
              screenWidth >= 961 ? (
                <DiscoverSieve
                  industriesPlatform={industriesPlatform}
                  type="Platforms"
                  industriesAll={industriesAll}
                  count={platformsAmount?.length || 0}
                />
              ) : null
            ) : (
              <SkeletonFilters />
            )}
          </div>
          <div className={styles.RightSide}>
            <div className={styles.FilterMob}>
              <div className={styles.FilterType}>
                {Number.isInteger(platformsAmount?.length || 0) && (
                  <P2 weight="light" className={styles.Count}>
                    {`${platformsAmount?.length} platform${platformsAmount?.length === 1 ? '' : 's'}`}
                  </P2>
                )}
                {checketType?.item ? (
                  <div className={styles.ContaimerType}>
                    {checketType.item.map(text => (
                      <TypeFilter
                        key={text}
                        name={text}
                        handleIsChecked={() =>
                          handleSelectOption({
                            name: checketType.name,
                            item: text,
                            multiple: checketType.multiple
                          })
                        }
                      />
                    ))}
                  </div>
                ) : null}
              </div>

              {investmentsActual.length && screenWidth <= 960 && screenWidth > 0 ? (
                <DiscoverSieve
                  industriesPlatform={industriesPlatform}
                  type="Platforms"
                  industriesAll={industriesAll}
                  count={platformsAmount?.length || 0}
                />
              ) : null}
            </div>

            {investmentsActual.length ? (
              <div className={styles.Investments}>
                {filanPlatforms.length ? (
                  <>
                    {filanPlatforms.map((investment, index) => (
                      <Fragment key={`platform-${index}`}>
                        {investment?.dataPointTitle1 ? (
                          <Offers key={`offer-${index}`} {...investment} />
                        ) : (
                          <InvestmentCard investment={investment} industriesAvgAll={industriesAvgAll} />
                        )}
                        {/* // checkIndexOffer(index) && combineOffers.items[investment.offer[0]]?.dataPointTitle1 ? ( //{' '}
                        <Offers key={`offer-${index}`} {...combineOffers.items[investment.offer[0]]} />
                        // ) : */}
                        {checkIndexOffer(index) && combineOffers.items[investment.offer[0]]?.title ? (
                          <ReadBlock key={`readBlock-${index}`} {...combineOffers.items[investment.offer[0]]} />
                        ) : null}
                        {checkIndexDiscoverCard(index) && isAuthChecked && !isLogged && (
                          <SubForm key={`subscrib-${index}`} />
                        )}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <NotFound text="Platforms not found" />
                )}
              </div>
            ) : (
              [...Array(4)].map((_, index) => <SkeletonCard key={index} />)
            )}
          </div>
          <div id="content" />
        </Container>
      </LockComponent>
    </div>
  )
}

PlatformsTab.propTypes = {
  assetPagesSlugs: PropTypes.arrayOf(PropTypes.string),
  industriesAll: PropTypes.arrayOf(PropTypes.object),
  investments: PropTypes.arrayOf(PropTypes.object),
  platformsToShow: PropTypes.number,
  searchBy: PropTypes.string,
  sortBy: PropTypes.string,
  filterBy: PropTypes.arrayOf(PropTypes.object),
  filterContains: PropTypes.arrayOf(PropTypes.string),
  isAuthChecked: PropTypes.bool,
  isLogged: PropTypes.bool,
  userId: PropTypes.string,
  offers: PropTypes.arrayOf(PropTypes.object),
  topIndustry: PropTypes.arrayOf(PropTypes.object),
  assetsTags: PropTypes.arrayOf(PropTypes.object),
  handleSetServiceField: PropTypes.func,
  handleSetFilterOption: PropTypes.func,
  handleSetDiscoverField: PropTypes.func,
  industries: PropTypes.arrayOf(PropTypes.string),
  banners: PropTypes.arrayOf(PropTypes.object),
  articlesLimited: PropTypes.arrayOf(PropTypes.object),
  industriesAvgAll: PropTypes.shape({})
}

PlatformsTab.defaultProps = {
  assetPagesSlugs: [],
  industriesAll: [],
  investments: [],
  platformsToShow: 4,
  searchBy: '',
  sortBy: '',
  offers: [],
  filterBy: [],
  filterContains: [],
  isAuthChecked: false,
  isLogged: false,
  topIndustry: [],
  userId: '',
  industries: [],
  assetsTags: [],
  articlesLimited: [],

  banners: [],
  industriesAvgAll: {},
  handleSetServiceField: () => {},
  handleSetDiscoverField: () => {},
  handleSetFilterOption: () => {}
}

export default PlatformsTab
