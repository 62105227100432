import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import wrapper from 'redux/store'
import { withRedis } from 'api/callConstructor'
import HeadTags from 'components/app/HeadTags/HeadTags'
import PlatformsTab from 'modules/discover/PlatformsTab/PlatformsTab'
import { setServiceFieldAction } from 'redux/services/actions'
import { setDiscoverFieldAction, setFilterOptionAction } from 'redux/discover/actions'
import {
  getAssetPagesCall,
  getAvgChangesReturnCall,
  getIndustriesAllCall,
  getInvestmentsCall,
  getLimitedArticlesCall
} from 'api/calls'
import { sortEndtries } from 'utils'
import { redis } from 'utils/redis'

const PlatformsTabContainer = props => {
  const { clusters } = useSelector(({ services }) => services)
  const { platformsToShow, searchBy, sortBy, filterBy, filterContains } = useSelector(({ discover }) => discover)
  const {
    isAuthChecked,
    isLogged,
    userData: { userId }
  } = useSelector(({ auth }) => auth)
  const { banners } = useSelector(({ app }) => app)

  const dispatch = useDispatch()

  const setServiceField = useCallback(payload => dispatch(setServiceFieldAction(payload)), [dispatch])
  const setDiscoverField = useCallback(payload => dispatch(setDiscoverFieldAction(payload)), [dispatch])
  const setFilterOption = useCallback(payload => dispatch(setFilterOptionAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    clusters,
    platformsToShow,
    searchBy,
    sortBy,
    filterBy,
    filterContains,
    isAuthChecked,
    isLogged,
    userId,
    banners,
    handleSetFilterOption: setFilterOption,
    handleSetServiceField: setServiceField,
    handleSetDiscoverField: setDiscoverField
  }

  return (
    <>
      <HeadTags title="Platforms" />
      <PlatformsTab {...updatedProps} />
    </>
  )
}

export const getStaticProps = wrapper.getStaticProps(async () => {
  const industriesAll = await withRedis(redis, getIndustriesAllCall(), '/discover')
  // if (!industriesAll.success)
  //   throwSentryError(
  //     `Error: API URL: https://cms.moneymade.io/api/industries. ${industriesAll.status} ${industriesAll.message}`
  //   )

  const assetPagesSlugs = await withRedis(redis, getAssetPagesCall(), '/discover')
  // if (!assetPagesSlugs.success)
  //   throwSentryError(
  //     `Error: API URL: https://cms.moneymade.io/api/asset-pages?publishedStatus=published. ${assetPagesSlugs.status} ${assetPagesSlugs.message}`
  //   )

  const investmentsPickFields =
    '?pick-fields=[*]id&pick-fields=[*]publishedStatus&pick-fields=[*]logo&&pick-fields=[*]profile&pick-fields=[*]mmPremium&pick-fields=[*]premium&pick-fields=[*]collapsedDescription&pick-fields=[*]target&pick-fields=[*]dataIndustry&pick-fields=[*]brandVideo&pick-fields=[*]promoVideo&pick-fields=[*]name&pick-fields=[*]slug&pick-fields=[*]industries&pick-fields=[*]global&pick-fields=[*]investors&pick-fields=[*]minInvestment&pick-fields=[*]newRisks&pick-fields=[*]paid&pick-fields=[*]objectives&pick-fields=[*]fees&pick-fields=[*]industry&pick-fields=[*]subIndustry&pick-fields=[*]description&pick-fields=[*]averageRating&pick-fields=[*]media'

  const investments = await withRedis(redis, getInvestmentsCall(investmentsPickFields), '/discover')
  // if (!investments.success)
  //   throwSentryError(
  //     `Error: API URL: https://cms.moneymade.io/api/entries${investmentsPickFields}. ${investments.status} ${investments.message}`
  //   )

  const slugIndustries = [
    ...new Set(industriesAll.result.map(({ dataSlug }) => `${dataSlug ? `slugs=${dataSlug}` : ''}`))
  ].join('&')

  const articlespickFields =
    'pick-fields=[*]slug&pick-fields=[*]title&pick-fields=[*]logo&pick-fields=[*]cardColor&pick-fields=[*]published&pick-fields=[*]industries&pick-fields=[*]logoThumbnail&pick-fields=[*]bigImage'

  const resultLimited = await withRedis(
    redis,
    getLimitedArticlesCall(`${articlespickFields}&_limit=${16}`),
    '/discover'
  )
  // if (!resultLimited.success)
  //   throwSentryError(
  //     `Error: API URL: https://cms.moneymade.io/api/articles?_sort=created_at:DESC&publishedStatus=published&${articlespickFields}&_limit=${16}. ${
  //       resultLimited.status
  //     } ${resultLimited.message}`
  //   )

  const resultAvg = await withRedis(
    redis,
    getAvgChangesReturnCall(slugIndustries.replace('&slugs=&slugs=', '&slugs=')),
    '/discover'
  )
  // if (!resultAvg.success)
  //   throwSentryError(
  //     `Error: API URL: https://mm-industries.moneymade.io/${slugIndustries.replace('&slugs=&slugs=', '&slugs=')}. ${
  //       resultAvg.status
  //     } ${resultAvg.message}`
  //   )

  const platformsDataSorted = sortEndtries(investments.result)

  return {
    props: {
      industriesAvgAll: resultAvg.success ? resultAvg.result : {},
      industriesAll: industriesAll.result || [],
      investments: platformsDataSorted || [],
      offers: [],
      articlesLimited: resultLimited.success ? resultLimited.result : [],
      assetPagesSlugs: assetPagesSlugs.result
    },
    revalidate: 3600 // 1 hour
  }
})

export default PlatformsTabContainer
