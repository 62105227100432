import { useMemo, useState, useEffect } from 'react'
import Link from 'next/link'
import { H5, MainButton, P2, useWindowSize } from '@moneymade/moneymade-ui'
import moment from 'moment'
import PropTypes from 'prop-types'

import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import { getAvgReturnCall } from 'api/calls'
import { ROUTES } from 'static/menusConstants'
import { getCdnImg } from 'utils/getters'
import CardGraph from './CardGraph/CardGraph'
import styles from './ReadBlock.module.scss'

const ReadBlock = ({ title: titleArticle, slug, logo, logoThumbnail, bigImage, cardColor, published, industries }) => {
  const { screenWidth } = useWindowSize()
  const [dataAvg, setDataAvg] = useState(0)

  const date = useMemo(() => {
    const publishDate = `${new Date(published).getFullYear()}-${new Date(published).getMonth() + 1}-${new Date(
      published
    ).getDate()}`

    const today = moment().subtract(0, 'days').format('YYYY-M-D')
    const yesterday = moment().subtract(1, 'days').format('YYYY-M-D')
    const publishTime = moment(published).format('hh:mm a')

    return publishDate === today
      ? `Today at ${publishTime}`
      : publishDate === yesterday
      ? `Yesterday at ${publishTime}`
      : moment(published).format('MMM DD')
  }, [published])

  useEffect(() => {
    const handleAvgCalls = async () => {
      // Get added platform
      if (industries[0]?.dataSlug) {
        const { success, result } = await getAvgReturnCall(industries[0]?.dataSlug)()
        if (success) setDataAvg(result)
      }
    }
    handleAvgCalls()
  }, [industries])

  return (
    <Link href={`/${ROUTES.learnArticle}/${slug}`} prefetch={false}>
      <a className={styles.ReadBlock}>
        <div className={styles.Left}>
          <div className={styles.ImagesContainer} style={{ backgroundColor: cardColor || '#4BEB90' }}>
            <ImgLazy
              src={getCdnImg(bigImage || logoThumbnail || logo)}
              alt={titleArticle}
              className={styles.ImageArticle}
              width={96}
              height={96}
            />
          </div>
          <div className={styles.ArticleContent}>
            <div className={styles.Date}>
              {industries?.length ? (
                <div className={styles.Link}>
                  <P2 weight="normal" className={styles.Title}>
                    {industries[0]?.name}
                  </P2>
                </div>
              ) : null}
              {industries?.length ? <P2 className={styles.Name}> •</P2> : null}
              {published && (
                <P2 className={styles.Name}>
                  {screenWidth < 601 && date.length > 15 ? `${date.slice(0, 10)}...` : date}
                </P2>
              )}
            </div>

            {titleArticle && (
              <H5 weight="bold" className={styles.Title}>
                {titleArticle}
              </H5>
            )}
          </div>
        </div>
        <div className={styles.Graph}>
          <CardGraph name={industries[0]?.name} avg={dataAvg} />
          {screenWidth < 601 && screenWidth > 0 ? (
            <MainButton
              color="dark"
              size="sm"
              weight="normal"
              iconAfter={<i className="icon-arrow-right" />}
              fontType="main"
            >
              Read
            </MainButton>
          ) : null}
        </div>
      </a>
    </Link>
  )
}

ReadBlock.propTypes = {
  title: PropTypes.string,
  cardColor: PropTypes.string,
  logo: PropTypes.string,
  published: PropTypes.string,
  slug: PropTypes.string,
  logoThumbnail: PropTypes.string,
  bigImage: PropTypes.string,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      dataSlug: PropTypes.string
    })
  )
}

ReadBlock.defaultProps = {
  title: '',
  cardColor: '',
  logo: '',
  published: '',
  slug: '',
  logoThumbnail: '',
  bigImage: '',
  industries: []
}

export default ReadBlock
