import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTransition, animated } from 'react-spring'
import { P3 } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'

import styles from './CustomTooltip.module.scss'

const CustomTooltip = ({ className, tooltipText, title, children, color }) => {
  const [isOpen, setOpen] = useState(null)
  const [modalPos, setModalPos] = useState({
    top: 0,
    left: 0
  })

  const transition = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 100
    }
  })

  const classes = [
    'mu-tooltip-modal',
    styles.CustomTooltip,
    styles.RightPos,
    ...(color === 'blue' ? [styles.Blue] : []),
    ...(color === 'red' ? [styles.Red] : [])
  ].join(' ')

  useEffect(() => {
    if (!isOpen || typeof isOpen === 'boolean') return

    const currentTarget = isOpen
    const { top, left, width, height } = currentTarget.getBoundingClientRect()

    setModalPos({
      top: top + window.pageYOffset + height / 2,
      left: left + window.pageXOffset + width
    })
  }, [isOpen])

  return (
    <>
      <div
        className={`mu-tooltip ${className}`}
        onMouseOver={event => setOpen(event.currentTarget)}
        onMouseOut={() => setOpen(null)}
        onFocus={() => {}}
        onBlur={() => {}}
      >
        {children}
      </div>
      {transition(
        (springStyles, item) =>
          item &&
          createPortal(
            <animated.div style={springStyles}>
              <div className={classes} style={{ ...modalPos, maxWidth: '150px' }}>
                <P3 weight="semibold">{title}</P3>
                {tooltipText}
              </div>
            </animated.div>,
            document.body
          )
      )}
    </>
  )
}

CustomTooltip.propTypes = {
  tooltipText: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node
}

CustomTooltip.defaultProps = {
  className: '',
  tooltipText: '',
  title: '',
  color: 'blue',
  children: null
}

export default CustomTooltip
