import PropTypes from 'prop-types'
import { H4, Icon, MainButton } from '@moneymade/moneymade-ui'

import styles from './LockBlock.module.scss'

const LockBlock = ({ title, handleSetUserFields }) => (
  <section className={styles.LockBlock}>
    <div className={styles.Header}>
      <H4 weight="semibold" className={styles.Title}>
        {title}{' '}
      </H4>
      <Icon icon="icon-lock" className={styles.LockIcon} size={18} />
    </div>

    <div className={styles.Center}>
      <div className={styles.Top}>
        <div className={styles.TopTextStart}>
          <H4 weight="semibold" className={styles.TextStart}>
            Become a <span className={styles.Member}>Member</span>
          </H4>

          <Icon icon="icon-verified-mark" size={15} />
        </div>

        <H4 weight="semibold" className={styles.TextEnd}>
          for Free to Unlock Access
        </H4>
      </div>

      <MainButton
        weight="light"
        fontType="main"
        onClick={() =>
          handleSetUserFields([
            { key: 'isAuthModal', value: true },
            { key: 'authModalType', value: 'signUp' }
          ])}
      >
        Join Now
      </MainButton>
    </div>
  </section>
)

LockBlock.propTypes = {
  title: PropTypes.string,
  handleSetUserFields: PropTypes.func
}

LockBlock.defaultProps = {
  title: '',
  handleSetUserFields: () => {}
}

export default LockBlock
