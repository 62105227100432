import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { setFilterOptionAction } from 'redux/discover/actions'
import DiscoverSieve from './DiscoverSieve'

const DiscoverSieveContainer = props => {
  const { filterBy, filterContains, sortBy } = useSelector(({ discover }) => discover)

  const dispatch = useDispatch()

  const setFilterOption = useCallback(payload => dispatch(setFilterOptionAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    filterBy,
    filterContains,
    sortBy,
    handleSetFilterOption: setFilterOption
  }

  return <DiscoverSieve {...updatedProps} />
}

export default DiscoverSieveContainer
