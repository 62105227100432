import { P2, P3 } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { toMoneyFormat } from 'utils'
import Graph from './Graph/Graph'
import styles from './CardGraph.module.scss'

const CardGraph = ({ name, avg }) => {
  const graph = useMemo(() => {
    const graphCard = Array.from(Array(20).keys()).map(key => ({
      x: key,
      y: Math.floor(Math.random() * 9)
    }))

    if (avg < 0) {
      graphCard[graphCard.length - 1].y = -10
      graphCard[graphCard.length - 2].y = -8
    } else {
      graphCard[graphCard.length - 1].y = 10
      graphCard[graphCard.length - 2].y = 8
    }

    return graphCard
  }, [avg])

  return (
    <div className={styles.CardGraph}>
      <P2 weight="normal">{name}</P2>
      {avg !== 0 ? (
        <div className={styles.Header}>
          <P3 weight="normal" className={avg < 0 ? 'color-red' : 'color-green'}>
            {avg > 0 ? '+' : ''}
            {typeof avg === 'number' ? `${toMoneyFormat(avg)}%` : avg}
          </P3>
          <P3 className={styles.Name}> •</P3>
          <P3 className={styles.Name}>Avg</P3>
        </div>
      ) : null}
      {graph.length ? (
        <div className={styles.Graph}>
          <Graph graph={graph} value={toMoneyFormat(avg)} />
        </div>
      ) : null}
    </div>
  )
}

CardGraph.propTypes = {
  name: PropTypes.string,
  avg: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

CardGraph.defaultProps = {
  name: '',
  avg: 0
}
export default CardGraph
