import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setFilterOptionAction, setFilterContainsOptionAction, resetSieveAction } from 'redux/discover/actions'
import FilterOptions from './FilterOptions'

const FilterOptionsContainer = props => {
  const { filterBy } = useSelector(({ discover }) => discover)

  const dispatch = useDispatch()

  const setFilterOption = useCallback(payload => dispatch(setFilterOptionAction(payload)), [dispatch])
  const setFilterContainsOption = useCallback(payload => dispatch(setFilterContainsOptionAction(payload)), [dispatch])
  const resetSieve = useCallback(payload => dispatch(resetSieveAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    filterBy,
    handleSetFilterOption: setFilterOption,
    handleSetFilterContainsOption: setFilterContainsOption,
    handleResetSieve: resetSieve
  }

  return <FilterOptions {...updatedProps} />
}

export default FilterOptionsContainer
