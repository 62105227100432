import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDiscoverFieldAction } from 'redux/discover/actions'

import Search from './Search'

const SearchContainer = props => {
  const { searchBy } = useSelector(({ discover }) => discover)

  const dispatch = useDispatch()

  const setSearch = useCallback(
    payload => dispatch(setDiscoverFieldAction({ key: 'searchBy', value: payload })),
    [dispatch]
  )

  const updatedProps = {
    ...props,
    searchBy,
    handleSetStoreSearch: setSearch
  }

  return <Search {...updatedProps} />
}

export default SearchContainer
