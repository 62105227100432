import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setUserFieldsAction } from 'redux/auth/actions'
import LockBlock from './LockBlock'

const LockBlockContainer = props => {
  const dispatch = useDispatch()

  const setUserFields = useCallback(payload => dispatch(setUserFieldsAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    handleSetUserFields: setUserFields
  }

  return <LockBlock {...updatedProps} />
}

export default LockBlockContainer
