import dynamic from 'next/dynamic'
import { Fragment, useEffect, useState, useMemo } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { MainButton, useWindowSize, P2, Icon, P3 } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'
import Link from 'next/link'

import useTracker from 'hooks/useTracker'
import { FILTER_SIEVE, FILTER_SIEVE_EXPANDE } from 'modules/discover/discoverConstants'
import FilterOptions from 'modules/discover/DiscoverSieve/FilterOptions/FilterOptionsContainer'
import Search from 'modules/discover/DiscoverSieve/Search/SearchContainer'
import { ROUTES } from 'static/menusConstants'
import styles from './DiscoverSieve.module.scss'

const SieveHeader = dynamic(() => import('modules/discover/DiscoverSieve/SieveHeader/SieveHeaderContainer'))
const FilterModal = dynamic(() => import('modules/discover/DiscoverSieve/FilterModal/FilterModalContainer'))

const DiscoverSieve = ({
  count,
  industriesPlatform,
  industriesAll,
  handleSetFilterOption,
  filterBy,
  filterContains,
  sortBy
}) => {
  const { screenWidth } = useWindowSize()
  const { handleUseTracker } = useTracker()

  const [isBottomReached, setBottomReached] = useState(false)
  const [stickyHeader, setStickyHeader] = useState(false)
  const [filterModal, setFilterModal] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState({
    modalFilters: 0,
    all: 0
  })

  const cleanIndustries = useMemo(
    () => industriesAll.filter(({ name }) => industriesPlatform.includes(name)),
    [industriesAll, industriesPlatform]
  )

  // Get applied filters count
  useEffect(() => {
    // More btn
    const modalFilters = FILTER_SIEVE_EXPANDE.map(({ name }) => name)
    const modalAppliedFilters = filterBy.filter(({ name }) => modalFilters.includes(name))
    // Global filters
    const allFilters = FILTER_SIEVE.map(({ name }) => name)
    const allAppliedFilters = filterBy.filter(({ name }) => allFilters.includes(name))

    let allModal = modalAppliedFilters.length + filterContains.length
    let allGlobal = allAppliedFilters.length + filterContains.length

    // Clalculate sort options
    if (sortBy) {
      allModal += 1
      allGlobal += 1
    }

    setAppliedFilters({ modalFilters: allModal, all: allGlobal })
  }, [filterBy, filterContains, sortBy])

  // Show modals after user reached bottom of the page and scroll top little bit
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://moneymade.activehosted.com/f/embed.php?id=11'
    script.type = 'text/javascript'
    script.charSet = 'utf-8'

    const scroll = () => {
      const scrollY = window.scrollY + window.innerHeight + 2
      const bodyScroll = document.body.offsetHeight

      // If the bottom of the page
      if (scrollY >= bodyScroll) {
        setBottomReached(true)
      }
      // If scrolled top after reached bottom
      if (isBottomReached && scrollY < bodyScroll) {
        if (!document.querySelector('[src="https://moneymade.activehosted.com/f/embed.php?id=11"]')) {
          document.head.appendChild(script)
        }

        window.removeEventListener('scroll', scroll)
      }
    }

    window.addEventListener('scroll', scroll)

    return () => {
      try {
        window.removeEventListener('scroll', scroll)
        document.head.removeChild(script)
      } catch {
        window.removeEventListener('scroll', scroll)
      }
    }
  }, [isBottomReached])

  useEffect(() => {}, [])

  const handleIsChecked = filterVariant => {
    if (filterVariant.multiple) {
      const selected = filterBy.find(({ name }) => name === filterVariant.name)
      return selected ? selected.item.includes(filterVariant.item) : false
    }

    return filterBy.some(filter => JSON.stringify(filter) === JSON.stringify(filterVariant))
  }

  const handleSelectOption = filter => {
    handleSetFilterOption(filter)
    // Track filter changes
    handleUseTracker('FilterUsed', filter.name, filter.item)
  }

  const handleSelectType = (name, nameIndustry, multiple) => {
    window.scrollTo(0, 200)

    handleSelectOption({ name, item: nameIndustry, multiple })
  }

  return (
    <>
      <FilterModal
        industries={cleanIndustries}
        isOpen={filterModal}
        investmentsCount={count}
        handleCloseModal={() => setFilterModal(false)}
      />

      {stickyHeader && screenWidth > 0 && screenWidth < 961 && (
        <SieveHeader allFiltersCount={appliedFilters.all} handleSetFilterModal={() => setFilterModal(true)} />
      )}
      <section className={styles.DiscoverSieve}>
        <VisibilitySensor onChange={isVisible => setStickyHeader(!isVisible)}>
          <div className={styles.Sieve}>
            <div className={styles.Search}>
              <Search />
            </div>
            <div className={styles.FilterOptions}>
              <FilterOptions
                modalFiltersCoutn={appliedFilters.modalFilters}
                allFiltersCount={appliedFilters.all}
                count={count}
                handleOpenModal={() => setFilterModal(true)}
              />
            </div>
            {screenWidth > 961 ? (
              <div className={styles.MatchMe}>
                <img src="https://assets.moneymade.io/images/login/match.svg" alt="MatchMe" width={90} height={16} />
                <P2>Not sure what investments are best for you?</P2>
                <Link href={`/${ROUTES.trending}`}>
                  <a className={styles.Link}>
                    <P3 weight="normal">Get Matched</P3>
                  </a>
                </Link>
              </div>
            ) : null}

            {screenWidth >= 961 ? (
              <div className={styles.FilterType}>
                {[
                  {
                    label: 'Type',
                    name: 'industry',
                    multiple: true,
                    items: cleanIndustries
                  }
                ].map(({ name, multiple, items }, index) => (
                  <Fragment key={index}>
                    <div className={styles.Items}>
                      {items.slice(0, showMore ? items.length : 8).map(({ name: nameIndustry, iconClass }, indx) => (
                        <div
                          aria-hidden
                          key={`${nameIndustry}-${indx}`}
                          className={`${styles.Item} ${
                            handleIsChecked({ name, item: nameIndustry, multiple }) ? styles.Active : ''
                          }`}
                          onClick={() => handleSelectType(name, nameIndustry, multiple)}
                        >
                          <P2>
                            {iconClass ? (
                              <Icon
                                icon={iconClass}
                                size={15}
                                className={handleIsChecked({ name, item: nameIndustry, multiple }) ? styles.Icon : ''}
                              />
                            ) : null}
                            {nameIndustry}
                          </P2>
                          {handleIsChecked({ name, item: nameIndustry, multiple }) ? (
                            <Icon icon="icon-check-mark" size={15} />
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </Fragment>
                ))}

                <button type="button" className={styles.ShowMore} onClick={() => setShowMore(prev => !prev)}>
                  {showMore ? (
                    <>
                      Hide <Icon icon="icon-point-up" size={14} />
                    </>
                  ) : (
                    <>
                      Show All <Icon icon="icon-point-down" size={14} />
                    </>
                  )}
                </button>
              </div>
            ) : null}

            <MainButton
              size="sm"
              color="dark"
              iconBefore={<i className="icon-filter" />}
              className={styles.FilterBtn}
              onClick={() => setFilterModal(true)}
            >
              Filter
            </MainButton>
          </div>
        </VisibilitySensor>
      </section>
    </>
  )
}

DiscoverSieve.propTypes = {
  count: PropTypes.number,
  filterBy: PropTypes.arrayOf(PropTypes.object),
  industriesAll: PropTypes.arrayOf(PropTypes.string),
  filterContains: PropTypes.arrayOf(PropTypes.string),
  handleSetFilterOption: PropTypes.func,
  sortBy: PropTypes.string,
  industriesPlatform: PropTypes.string
}

DiscoverSieve.defaultProps = {
  count: null,
  filterBy: [],
  industriesAll: [],
  filterContains: [],
  handleSetFilterOption: () => {},
  sortBy: '',
  industriesPlatform: ''
}

export default DiscoverSieve
